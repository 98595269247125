
























































































































import { Component, Vue } from 'vue-property-decorator';

import FeedbackPermission from '@/components/FeedbackPermission/index.vue';
import PlusPlanIcon from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { URLS_REDACTION } from '@/constant/FreePlanUrls';

const TITLE_PLAN_FREE = 'Você é aluno Explicaê Free e a redação não está disponível para você.';
const TEXT_BUTTON_PLAN_FREE = 'Quero assinar um plano';
const NAME_FEATURE_PLAN = 'à Redação';

@Component({
  components: {
    FeedbackPermission,
    PlusPlanIcon,
    CheckPlanIcon
  }
})
export default class FeedbackPermissionRedaction extends Vue {
  private URLS_REDACTION = URLS_REDACTION;

  get plan() {
    return this.$store.getters.profile.plan;
  }

  get planIsFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get feedback() {
    return {
      title: this.planIsFree ? TITLE_PLAN_FREE : '',
      nameFeature: this.planIsFree ? '' : NAME_FEATURE_PLAN,
      textButton: this.planIsFree ? TEXT_BUTTON_PLAN_FREE : 'Quero assinar'
    };
  }
}
