




















































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import Thumb from '@/components/Thumb/index.vue';
import Loading from '@/components/Loading/Loading.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';
import Lock from '@/assets/icons/Lock.vue';

import Arrow from '@/assets/icons/Arrow.vue';

import TopRedactionLoading from './TopRedactionLoading.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import {
  Redaction as IRedaction,
  Category as ICategory,
  SelectCategory as ISelectCategory,
  Theme as ITheme
} from './ITopRedaction';

const VALUE_ALL_THEMES_OPTION = 'Todos';
const TEXT_ERRO_LOADING_REDACTIONS = 'Não foi possível carregar a lista de redações nota 1000.';
const TEXT_NO_REDACTION = 'No momento, não temos redação com esse tema.';

@Component({
  components: {
    Thumb,
    Arrow,
    Loading,
    FeedbackUser,
    TopRedactionLoading,
    Tooltip,
    Lock
  }
})
export default class TopRedaction extends Vue {
  @Prop({ required: true }) list!: [];
  @Prop({ default: true }) isPermission!: boolean;

  private setModal = new ActiveModal();

  private isLoadingListRedactions = true;
  private isLoadingListThemes = false;

  private pageListRedactions = 0;
  private totalRedactions = 0;
  private totalCurrentRedactions = 0;

  private selectIdCategory = '';
  private selectIdTheme = '';
  private textFeedback = TEXT_NO_REDACTION;

  private listRedactions: Array<IRedaction> = [];
  private listThemes: Array<ITheme> = [];

  private RedactionService = new RedactionService();

  created() {
    this.getListRedaction();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get getListCategories() {
    const category: Array<ISelectCategory> = this.list.map((categoryItem: ICategory) => ({
      id: categoryItem.id,
      title: categoryItem.title
    }));

    return category;
  }

  get hasMoreRedaction() {
    return this.totalCurrentRedactions < this.totalRedactions;
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  async getListRedaction() {
    try {
      this.isLoadingListRedactions = true;

      const theme = this.selectIdTheme === VALUE_ALL_THEMES_OPTION ? '' : this.selectIdTheme;

      const data = {
        page: this.pageListRedactions,
        limit: 5,
        ...(this.selectIdCategory && { idCategory: Number(this.selectIdCategory) }),
        ...(theme && { idTheme: Number(theme) })
      };

      const response = await this.RedactionService.getRedactionsHundred(data);

      this.listRedactions.push(...response?.redactions);

      this.totalRedactions = response?.totalRedactions;
      this.totalCurrentRedactions = this.listRedactions.length;

      this.moveScrollList('listRedaction');
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as redações.',
        status: 'error'
      });
      this.textFeedback = TEXT_ERRO_LOADING_REDACTIONS;
      console.error(error);
    } finally {
      this.isLoadingListRedactions = false;
    }
  }

  getListThemes() {
    this.isLoadingListThemes = true;
    this.selectIdTheme = '';
    let category: ICategory | undefined;

    if (this.selectIdCategory) {
      category = this.list.find(
        (categoryItem: ICategory) => categoryItem.id === Number(this.selectIdCategory)
      );
    }

    this.listThemes = category?.themes || [];
    this.isLoadingListThemes = false;
  }

  async setSelectCategory(event: any) {
    this.resetListThemes();
    this.resetPageListRedactions();
    this.resetTotalRedactions();
    this.resetListRedactions();

    this.selectIdCategory = event.target.value;
    this.getListThemes();
    await this.getListRedaction();
  }

  async setSelectTheme(event: any) {
    this.resetPageListRedactions();
    this.resetTotalRedactions();
    this.resetListRedactions();

    this.selectIdTheme = event.target.value;
    if (this.selectIdTheme) await this.getListRedaction();
  }

  goToShowRedaction(redactionId: string) {
    if (!this.isPermission) {
      this.setShowModal();

      return;
    }

    this.setTrackAmplitude();

    this.$router.push({
      name: 'NoteAThousandRedaction',
      params: {
        redactionId
      }
    });
  }

  async showMoreRedactions() {
    if (this.hasMoreRedaction) {
      this.pageListRedactions += 1;
      await this.getListRedaction();
    }
  }

  moveScrollList(refList: string) {
    const list: any = this.$refs[refList];

    if (list && list.scrollHeight) {
      this.$nextTick(() => {
        list.scrollTop = list.scrollHeight;
      });
    }
  }

  resetPageListRedactions() {
    this.pageListRedactions = 0;
  }

  resetTotalRedactions() {
    this.totalRedactions = 0;
    this.totalCurrentRedactions = 0;
  }

  resetListRedactions() {
    this.listRedactions = [];
  }

  resetListThemes() {
    this.listThemes = [];
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'TopRedaction',
          local: 'Ver Redação'
        }
      }
    });
  }
}
