












































































import { Component, Vue, Prop } from 'vue-property-decorator';

import FeedbackUser from '@/components/ContainerFluid/ContainerFluid.vue';
import LineChartAll from '@/components/Graphics/LineChartAll.vue';
import DoughnutChart from '@/components/Graphics/DoughnutChart.vue';

import MyPerformanceLoading from './MyPerformanceLoading.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { Performance } from './IMyPerformance';

@Component({
  components: {
    MyPerformanceLoading,
    LineChartAll,
    DoughnutChart,
    FeedbackUser
  },
  filters: {
    toFixedValue(value: string | number) {
      return Number(value).toFixed(2);
    }
  }
})
export default class MyPerformance extends Vue {
  @Prop({ default: true }) isPermission!: boolean;

  private isLoading = true;

  private performanceData!: Performance;

  private RedactionService = new RedactionService();

  private setModal = new ActiveModal();

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  created() {
    this.getMyPerformance();
  }

  async getMyPerformance() {
    try {
      this.isLoading = true;

      const response = await this.RedactionService.getMyPerformance();

      this.performanceData = response;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as estatísticas.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  goToMyRedactions() {
    this.setTrackAmplitude();

    this.$router.push({
      name: 'MyRedactions'
    });
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'MyPerformance',
          local: 'Ver minhas redações'
        }
      }
    });
  }
}
