










































import { Component, Prop, Mixins } from 'vue-property-decorator';

import iPen from '@/assets/icons/Pen.vue';

import openInstructionRedaction from '@/mixins/openInstructionRedaction';

import Tooltip from '@/components/Tooltip/index.vue';
import Lock from '@/assets/icons/Lock.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { BestTheme as IBestTheme } from '../../interfaces/IRedaction';

@Component({
  components: {
    iPen,
    Tooltip,
    Lock
  }
})
export default class ListDevelopedThemes extends Mixins(openInstructionRedaction) {
  @Prop({ required: true }) list!: Array<IBestTheme>;
  @Prop({ default: true }) isPermission!: boolean;

  private setModal = new ActiveModal();

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  handleRedirectRedaction(redactionId: number, componentName: string) {
    if (!this.isPermission) {
      this.setShowModal();

      return;
    }

    this.goToInstruction(redactionId, componentName);
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }
}
